<template>
  <div id="setting">
    <h2>Setting</h2>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "setting",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "masters/settings" },
      { title: "Setting" },
    ]);
  }
};
</script>
